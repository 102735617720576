




















import Vue from 'vue';
import formatTime from '@/utils/formatTime';

export default Vue.extend({
  name: 'FieldTime',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    allowedHours: {
      type: Function,
      default: () => true,
    },
    allowedMinutes: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    formattedValue(): string {
      if (!this.value) return '';
      return formatTime(`2000-01-01 ${this.value}`);
    },
  },
});
