









import Vue from 'vue';
import { ROUTE_STATUSES } from '@/utils/constants';

export default Vue.extend({
  name: 'RouteStatusIcon',
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    statusIcon(): string {
      const icons = {
        [ROUTE_STATUSES.UNSTARTED as string]: '',
        [ROUTE_STATUSES.STARTED as string]: 'mdi-map-marker',
        [ROUTE_STATUSES.COMPLETED as string]: 'mdi-check',
      };
      return icons[this.status];
    },
    statusColor(): string {
      const colors = {
        [ROUTE_STATUSES.UNSTARTED as string]: '',
        [ROUTE_STATUSES.STARTED as string]: 'info',
        [ROUTE_STATUSES.COMPLETED as string]: 'success',
      };
      return colors[this.status];
    },
  },
});
