
























import Vue from 'vue';

export default Vue.extend({
  name: 'AppNotice',
  inheritAttrs: false,
  props: {
    to: {
      type: [String, Object],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    dark: Boolean,
  },
  computed: {
    element() {
      if (this.to) return 'router-link';
      return 'div';
    },
  },
});
