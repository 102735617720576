import parseISO from 'date-fns/parseISO';
import addDays from 'date-fns/addDays';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import pluralNumber from '@/utils/pluralNumber';

export default (value?: string | null): string => {
  if (!value) return '';
  const now = new Date();
  const tagTime = parseISO(value);
  if (tagTime.toString() === 'Invalid Date') return '';
  const tagExpireTime = addDays(tagTime, 3);
  const expiresInMinutes = differenceInMinutes(tagExpireTime, now);
  if (expiresInMinutes <= 0) return 'This tag has expired';
  const hours = Math.floor(expiresInMinutes / 60);
  const minutes = expiresInMinutes % 60;
  const h = pluralNumber(hours, 'hour');
  const m = pluralNumber(minutes, 'minute');
  return `This tag will expire in ${h} ${m}`;
};
