














































































































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import AppNotice from '@/components/AppNotice.vue';
import FieldWashTag from '@/components/FieldWashTag.vue';
import RouteStatusIcon from '@/components/RouteStatusIcon.vue';
import START_ROUTE from '@/graphql/queries/StartRoute.graphql';
import SCHEDULED_ROUTE from '@/graphql/queries/ScheduledRoute.graphql';
import USER_ROLE from '@/graphql/queries/UserRole.graphql';
import ROUTE_START from '@/graphql/mutations/RouteStart.graphql';
import { required } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import lightFormat from 'date-fns/lightFormat';
import formatDate from '@/utils/formatDate';
import { ROUTE_STATUSES, USER_ROLES } from '@/utils/constants';
import { GraphQLError } from 'graphql';
import {
  ScheduledRoute,
  Truck,
  Trailer,
  ScheduledRouteQuery,
  RouteStartMutationVariables,
  WashTagInput,
  ScheduledRouteQueryVariables,
  StartRouteQueryVariables,
  UserRoleQuery,
} from '@/types/schema';

export default Vue.extend({
  name: 'RouteStart',
  components: {
    AppMain,
    AppForm,
    AppNotice,
    FieldWashTag,
    RouteStatusIcon,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        route: '',
        truck: '',
        trailer: '',
        washTag: {
          photoFront: '',
          photoBack: '',
          tagTime: '',
        },
      },
      routes: [] as ScheduledRoute[],
      trucks: [] as Truck[],
      trailers: [] as Trailer[],
      route: {} as ScheduledRouteQuery['route'],
      user: null as UserRoleQuery['user'],
    };
  },
  computed: {
    isAdminStarting(): boolean {
      const hasParam = !!this.$route.params.id;
      const isAdmin = this.user?.role === USER_ROLES.ADMIN;
      return hasParam && isAdmin;
    },
    currentDate(): string {
      const today = new Date();
      const date = lightFormat(today, 'yyyy-MM-dd');
      return date;
    },
    currentRoute(): ScheduledRoute | undefined {
      if (this.isAdminStarting) {
        return this.routes.find((route) => route.id === this.$route.params.id);
      } else {
        return this.routes.find((route) => route.date === this.currentDate);
      }
    },
    startedRoute(): ScheduledRoute | undefined {
      if (this.isAdminStarting) return;
      const { STARTED } = ROUTE_STATUSES;
      return this.routes.find((route) => route.status === STARTED);
    },
    selectedRouteDelivery(): Record<string, unknown> {
      if (!this.route) return {};
      return this.route.delivery || {};
    },
    washTag(): WashTagInput {
      const { photoFront, photoBack, tagTime } = this.form.washTag;
      const photos = [{ file: photoFront }, { file: photoBack }];
      const uploads = photos.filter((photo) => !!photo.file);
      return { uploads, tagTime };
    },
  },
  watch: {
    currentRoute(newValue) {
      if (newValue) this.form.route = newValue.id;
    },
    route(newValue: ScheduledRoute) {
      if (newValue && newValue.trailer) this.form.trailer = newValue.trailer.id;
      if (newValue && newValue.truck) this.form.truck = newValue.truck.id;
    },
  },
  validations() {
    return {
      form: {
        route: { required },
        truck: { required },
        trailer: { required },
        washTag: {
          photoFront: { required },
          tagTime: { required },
        },
      },
    };
  },
  methods: {
    formatDate,
    validationMessages,
    startRouteQueryVariables(): StartRouteQueryVariables {
      if (this.isAdminStarting) {
        return {
          range: {
            start: '1970-01-01',
            end: '2099-12-12',
          },
          mine: false,
        };
      }

      return {
        mine: true,
      };
    },
    async startRoute() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        const response = await this.$apollo.mutate({
          mutation: ROUTE_START,
          variables: {
            input: {
              routeId: this.form.route,
              truckId: this.form.truck,
              trailerId: this.form.trailer,
              washTag: this.washTag,
            },
          } as RouteStartMutationVariables,
        });
        const { routeStart } = response.data;
        if (!routeStart?.route) return;

        const name = this.isAdminStarting
          ? 'admin-route-complete'
          : 'driver-route';

        this.$router.push({
          name,
          params: { id: routeStart.route.id },
        });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
  },
  apollo: {
    routes: {
      query: START_ROUTE,
      variables(): StartRouteQueryVariables {
        return this.startRouteQueryVariables();
      },
      fetchPolicy: 'cache-and-network',
    },
    trucks: {
      query: START_ROUTE,
      variables(): StartRouteQueryVariables {
        return this.startRouteQueryVariables();
      },
    },
    trailers: {
      query: START_ROUTE,
      variables(): StartRouteQueryVariables {
        return this.startRouteQueryVariables();
      },
    },
    route: {
      query: SCHEDULED_ROUTE,
      variables(): ScheduledRouteQueryVariables {
        return {
          id: this.form.route,
        };
      },
      skip(): boolean {
        return !this.form.route;
      },
    },
    user: {
      query: USER_ROLE,
    },
  },
});
