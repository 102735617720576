










import Vue from 'vue';
import FieldDateTime from '@/components/FieldDateTime.vue';
import tagExpireTime from '@/utils/tagExpireTime';

export default Vue.extend({
  name: 'FieldWashTagTime',
  components: {
    FieldDateTime,
  },
  inheritAttrs: false,
  computed: {
    expireTime(): string {
      return tagExpireTime(this.$attrs.value);
    },
  },
});
