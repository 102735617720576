























import Vue from 'vue';
import FieldDate from '@/components/FieldDate.vue';
import FieldTime from '@/components/FieldTime.vue';

export default Vue.extend({
  name: 'FieldDateTime',
  components: {
    FieldDate,
    FieldTime,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      date: '',
      time: '',
    };
  },
  computed: {
    dateTime(): string {
      if (!this.date || !this.time) return '';
      return `${this.date} ${this.time}`;
    },
  },
  watch: {
    date(newValue) {
      const refTime = this.$refs.time as Vue & { menuOpen: boolean };
      if (newValue && !this.time) refTime.menuOpen = true;
    },
    dateTime(newValue) {
      this.$emit('input', newValue);
    },
  },
});
