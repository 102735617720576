





























































import Vue from 'vue';
import loadImage from 'blueimp-load-image';

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export default Vue.extend({
  name: 'FieldPhoto',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Upload File',
    },
    id: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
    error: Boolean,
    clearable: Boolean,
  },
  data() {
    return {
      parsing: false,
    };
  },
  computed: {
    fieldId(): string {
      return this.id || `upload-${Math.random()}`;
    },
    listeners(): Record<string, unknown> {
      return {
        ...this.$listeners,
        input: this.input,
        change: this.input,
      };
    },
    isLoading(): boolean {
      return this.loading || this.parsing;
    },
    hasErrors(): boolean {
      return this.error || this.errorMessages.length > 0;
    },
  },
  methods: {
    input(event: HTMLInputEvent) {
      if (!event.target || !event.target.files) return;

      const [file] = event.target.files;

      if (file && (file.type.includes('jpeg') || file.type.includes('png'))) {
        this.parsing = true;
        loadImage(
          file,
          (canvas: HTMLCanvasElement) => {
            const image = canvas.toDataURL('image/jpeg');
            if (image) {
              this.parsing = false;
              this.$emit('input', image);
            }
          },
          {
            canvas: true,
            orientation: true,
            maxWidth: 1280,
            maxHeight: 1280,
          }
        );
      }
    },
  },
});
